<template>
  <div style="z-index: 99999999999999999999999">
<!--    <div class="experts_bottom">-->
<!--      <div style="display: flex;align-items: center;">-->
<!--        <img  src=".././assets/yun_icu_logo.png" alt="">-->
<!--        <span>打开云ICU  查阅更多内容</span>-->
<!--      </div>-->

<!--      <van-button round type="info">打开</van-button>-->
<!--    </div>-->
    <van-tabbar class="experts_foot" :placeholder="true" :fixed="true"  :border="false">
      <div class="experts_bottom">
        <div style="display: flex;align-items: center;font-size: small">
          <img  src=".././assets/yun_icu_logo.png" alt="">
          <span>打开云ICU  查阅更多内容</span>
        </div>
        <wx-open-launch-app v-if="this.isBlackApp()" appid="wxfbd04f2016be968f"  @launch="wxlaunchFn" @error="wxerrorFn" >
          <script type="text/wxtag-template">
            <style>.btn { display: flex;
              justify-content: center;
              align-items: center;
              width: 65px;
              border-radius: 22px;
              height: 40px;color: #fff;
              background-color: #1989fa;
              border: 1px solid #1989fa;
            }</style>
            <div class="btn">打开</div>
          </script>
        </wx-open-launch-app>

        <van-button v-else @click="button_click" round type="info">打开</van-button>
      </div>
    </van-tabbar>



  </div>
</template>

<script>

import {getWXAppId} from "@/service/subscribeShare_api";

export default {
  name: "share_foot",
  data(){
    return{
    }
  },
  mounted() {
    this.getWX_config()
  },
  methods:{
    async getWX_config(){
      const wx_config=await getWXAppId(location.href.split('#')[0])
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
        appId: wx_config.appId, // 必填，公众号的唯一标识
        timestamp: wx_config.timestamp, // 必填，生成签名的时间戳
        nonceStr: wx_config.nonceStr, // 必填，生成签名的随机串
        signature: wx_config.signature,// 必填，签名
        jsApiList: ['onMenuShareAppMessage'], // 必填，需要使用的JS接口列表,没有就随便填一个
        openTagList: ['wx-open-launch-app'] //必填， 要申请的开放标签名称
      })
    },
    wxlaunchFn(e){
      console.log("success", e, e.detail);
    },
    wxerrorFn(e){
      if(e.detail.errMsg === 'launch:fail') {
        //console.log('未安装app, 跳转下载地址');
        this.down_app()
      }
    },


    down_app(){
      let ran = navigator.userAgent
      let isAndroid = ran.indexOf('Android') > -1 || ran.indexOf('Linux') > -1
      let isIOS = !!ran.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)

      if (isAndroid) {
        // 安卓 跳转应用宝
        window.location.href = "https://a.app.qq.com/o/simple.jsp?pkgname=com.ian.icu&amp;amp;channel=0002160650432d595942&amp;amp;fromcase=60001";
      }
      if (isIOS) {
        window.location.href = "https://apps.apple.com/cn/app/%E4%BA%91icu/id1062118952";
      }
    },
    button_click(){
      //android和ios需要设置的app scheme package
      window.location.href= "wxfbd04f2016be968f://";
    },
    isBlackApp() {
      let u = navigator.userAgent.toLowerCase();
      return /micromessenger/i.test(u) || u.indexOf("weibo") > -1 || u.indexOf("qq") > -1 || u.indexOf('mqqbrowser') > -1;
    }
  },
}
</script>

<style lang="scss" scoped>
.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  border-radius: 22px;
  height: 40px;color: #fff;
  background-color: #1989fa;
  border: 1px solid #1989fa; }
.experts_foot{
  background-color: rgba(0,0,0,0);
  ::v-deep .van-tabbar--fixed{
    background-color: rgba(0,0,0,0.0);
  }
  .experts_bottom{
    width: 90%;
    //width: 350px;
    margin: 0 auto;
    background: #333333;
    border-radius: 25px;
    text-align:center;
    align-items: center;
    display:flex;
    justify-content: space-between;
    padding: 5px;
    color: white;
    img{
      height: 40px;
      width: 40px;
      border-radius: 40px;
    }
    span{
      margin-left: 8px;
    }
  }
}
</style>
